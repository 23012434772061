@import "sass-lib";

@media (min-width: 0px) and (max-width: 1300px) {
	header{
		height: 80px;padding: 0 25px;
		.logo{
			img{width: 120px;}
		}
		.nav-head{
			position: fixed;top: 81px;right: 0;left:auto;bottom:0;background: $white;padding: 20px 20px 0;width: 350px;@include boxSizing(border-box);display: none;font-family: $barlow;overflow: auto;
			&:before{content: "";position: fixed;top: 81px;left: 0;right: 0;bottom: 0;background: rgba(0,0,0,.8);z-index: -1;}
			&:after{content: "";position: fixed;top: 81px;right: 0;bottom: 0;background: $white;z-index: -1;width: 350px;}
			> li{
				float: none;display: block;border-bottom: 1px solid #f1f1f1;padding-bottom: 10px;margin: 0 0 10px;text-align: left;position: relative;
				a{font-size: 16px;}
				>a{
					padding-bottom: 0;margin-right: 30px;
					&:before{display:none;}
				}
				&.parent{
					&:before{content:"";position: absolute;top: 5px;right: 0;width: 16px;height: 9px;background: url('../images/material/arrow-mobile.png')no-repeat center;}
					> ul{
						position: relative;top: auto;left: auto;right: auto;height: auto;white-space: normal;overflow: inherit;display: none;@include transition(0s all);padding: 0;width: 100%;@include boxSizing(border-box);
						&:before{display:none;}
						&:after{display: none;}
						> li{
							display: block;margin-bottom: 5px;
							a{
								font-size: 14px;text-align: left;
								&:before{display:none;}
							}
							&:first-child{padding-top: 15px;}
							&:last-child{padding-bottom: 10px;}
						}
					}
					&:hover{
						> ul{height: auto;}
					}
				}
			}

			.lang-head{
				margin-bottom: 20px;
				> span{display: none;}
				.drop-lang{
					display: block !important;position: relative;top: auto;left: auto;right: auto;padding: 0;text-align: right;
					a{
						display: inline-block;padding: 0 10px;position: relative;
						&:before{content:"|";position: absolute;top: 0;right: -2px;}
						&:last-of-type{
							&:before{display:none;}
						}
					}
				}
			}
			.search-head{
				>span{display: none;}
				.drop-search{
					display: block !important;position: relative;top: auto;left: auto;right: auto;padding-left: 25px;opacity: 1 !important;
					input[type=text]{width: 100%;float: none;border: none;font-size: 16px;}
					input[type=submit]{right: auto;left: 0;}
				}
			}
		}
		.nav-right{
			margin-top: 30px;
			.burg-menu{
				width: 40px;float: left;margin-left: 30px;cursor: pointer;
				span{
					display:block;height: 4px;background: #1c2674;@include borderRadius(4px);margin-bottom: 6px;width: 100%;float: right;@include transition(.2s all ease-out);position: relative;
					&:nth-child(2){width: 30px;}
					&:nth-child(3){width: 35px;}
				}

				&.close{
					span{
						&:first-child{width:35px;@include transform(rotate(45deg));top: 10px;}
						&:nth-child(2){opacity: 0;}
						&:nth-child(3){@include transform(rotate(-45deg));top: -10px;}
					}
				}
			}
		}
		&.float{
			.logo{padding-top: 24px;}
			.nav-head{
				> li{
					margin-top: 0;
					> a{padding-bottom: 0;}
					&.parent{
						> ul{top: auto;}
					}
				}
			}
			.nav-right{margin-top: 30px;}
		}
	}
	.content{margin-top: 80px;}
	.search-head{
		> span{width: 25px;}
	}
	.lang-head{
		> span{
			padding-right: 30px;padding-left: 10px;
			&:before{right:13px;}
		}
	}
	.loader{
		.in-loader{
			.el{width: 80px;height: 113px;bottom: 15px;left: 40%;}
			.ii{width: 85px;height: 115px;top: 120px;}
			.text-logo{width: 700px;margin-left: -350px;}
		}
	}
	.home{
		h2{font-size: 28px;}
		.wrapper{width: 100%;padding: 0 30px;@include boxSizing(border-box);}

		&.qts{
			h2{font-size: 34px;}
		}
		&.solution{
			h2{margin-bottom: 20px;}
		}
		&.newsupdate{
			.wrap-newsupdate{
				.box-highlt{
					p{height: 90px;overflow: hidden;}
					.txt{
						padding: 20px 30px;
					}
				}
				.list-small{
					width: 31.6%;@include boxSizing(border-box);
					&:nth-child(4n){margin-right: 2.564%;}
					&:nth-child(3n){margin-right:0;}
					&:nth-child(n+4){display: none;}
				}
			}
		}
	}
	.sliderhome{
		.list{
			overflow: hidden;
			.text-banner{
				h2{font-size: 50px;line-height: 60px;margin-bottom: 15px;}
				p{font-size: 16px;line-height: 24px;}
			}
		}
	}
	.box-sliderhome{
		.ket{left: 25px;}
	}
	.box-value{
		.txt{
			padding-top: 20px;
			h3{margin-bottom: 15px;}
		}
	}
	.contenterror{
		.mid{
			h5{font-size: 20px;line-height: 26px;}
		}
	}

	footer{
		.wrapper{width: 100%;padding: 0 30px;@include boxSizing(border-box);}
	}
}

@media (min-width: 0px) and (max-width: 1020px) {
	h2{font-size: 33px;line-height: 40px;}
	h3{font-size: 28px;}
	.wrapper,.wrap-sm{width: 100%;@include boxSizing(border-box);padding: 0 20px;}
	header{
		height: 80px;
		.logo{
			img{width: 120px;}
		}
		.nav-head{
			position: fixed;top: 81px;right: 0;bottom:0;background: $white;padding: 20px 20px 0;width: 350px;@include boxSizing(border-box);display: none;font-family: $barlow;
			&:before{content: "";position: fixed;top: 81px;left: 0;right: 0;bottom: 0;background: rgba(0,0,0,.8);z-index: -1;}
			&:after{content: "";position: fixed;top: 81px;right: 0;bottom: 0;background: $white;z-index: -1;width: 350px;}
			> li{
				float: none;display: block;border-bottom: 1px solid #f1f1f1;padding-bottom: 10px;margin: 0 0 10px;text-align: left;position: relative;
				a{font-size: 16px;}
				>a{
					padding-bottom: 0;margin-right: 30px;
					&:before{display:none;}
				}
				&.parent{
					&:before{content:"";position: absolute;top: 5px;right: 0;width: 16px;height: 9px;background: url('../images/material/arrow-mobile.png')no-repeat center;}
					> ul{
						position: relative;top: auto;left: auto;right: auto;height: auto;white-space: normal;overflow: inherit;display: none;@include transition(0s all);padding: 0;
						&:before{display:none;}
						&:after{display: none;}
						> li{
							display: block;margin-bottom: 5px;
							a{
								font-size: 14px;text-align: left;
								&:before{display:none;}
							}
							&:first-child{padding-top: 15px;}
							&:last-child{padding-bottom: 10px;}
						}
					}
					&:hover{
						> ul{height: auto;}
					}
				}
			}

			.lang-head{
				margin-bottom: 20px;
				> span{display: none;}
				.drop-lang{
					display: block !important;position: relative;top: auto;left: auto;right: auto;padding: 0;text-align: right;
					a{
						display: inline-block;padding: 0 10px;position: relative;
						&:before{content:"|";position: absolute;top: 0;right: -2px;}
						&:last-of-type{
							&:before{display:none;}
						}
					}
				}
			}
			.search-head{
				>span{display: none;}
				.drop-search{
					display: block !important;position: relative;top: auto;left: auto;right: auto;padding-left: 25px;opacity: 1 !important;
					input[type=text]{width: 100%;float: none;border: none;font-size: 16px;}
					input[type=submit]{right: auto;left: 0;}
				}
			}
		}
		.nav-right{
			margin-top: 30px;
			.burg-menu{
				width: 40px;float: left;margin-left: 30px;cursor: pointer;
				span{
					display:block;height: 4px;background: #1c2674;@include borderRadius(4px);margin-bottom: 6px;width: 100%;float: right;@include transition(.2s all ease-out);position: relative;
					&:nth-child(2){width: 30px;}
					&:nth-child(3){width: 35px;}
				}

				&.close{
					span{
						&:first-child{width:35px;@include transform(rotate(45deg));top: 10px;}
						&:nth-child(2){opacity: 0;}
						&:nth-child(3){@include transform(rotate(-45deg));top: -10px;}
					}
				}
			}
		}
		&.float{
			.logo{padding-top: 24px;}
			.nav-head{
				> li{
					margin-top: 0;
					> a{padding-bottom: 0;}
					&.parent{
						> ul{top: auto;}
					}
				}
			}
			.nav-right{margin-top: 30px;}
		}
	}
	.loader{display: none;}
	.home{
		h2{font-size: 23px;line-height: 30px;}
		.tittle{max-width: 100%;}

		&.qts{
			h2{font-size: 28px;line-height: 36px;}
			&:before{ width: 100%; height: auto;  background-size: 100%; }
			.wrapper{width: 100%;}
		}
		&.solution{
			h2{margin-bottom: 15px;}
			.tittle-finan{max-width: 100%;}
			.box-solution{
				h4{font-size: 16px;}
			}
		}
		&.newsupdate{
			h3{margin-bottom: 10px;}
			.wrap-newsupdate{
				.box-highlt{
					position: relative;
					h3{font-size: 19px;line-height: 24px;max-height: 72px;}
					h6{font-size: 12px;}
					figure{
						position: absolute;top: 0;left: 0;bottom: 0;
						img{width: 100%;height: 100%;object-fit: cover;}
					}
					.txt{float: right;}
				}
			}
		}
	}
	.pop{
		.inpopup{
			width: 80%;padding: 35px 20px;
			.text{
				h4{
					font-size: 23px;line-height: 28px;padding-bottom: 10px;
					&:before{left:0;}
				}
			}
		}
		&#popHome{
	        .inpopup{
	            height: 384px;
	            .box-pop{
	                img{height: 380px;}
	            }
	        }
	    }
	}
	.sc-padd{padding: 40px 0;}
	.byside{
		margin-bottom: 20px;
		h2{font-size: 33px;line-height: 40px;}
		.linered{margin-left: 0;}
		.capt-small{margin-bottom: 15px;}
		.left{margin-bottom: 20px;}
	}
	.popmiddle{
		.inpopup{
			.text{
				h4{margin-bottom: 5px;}
				small{padding-bottom: 10px;}
			}
		}
	}
	.box-value{
		.txt{padding-right: 0;}
	}
	.box-financial{
		.txt{
			h5{margin-bottom: 10px;}
		}
	}
	.box-video{margin-bottom: 40px;}
	.box-career{
		.row{
			h5{margin-bottom: 5px;}
			.col{
				span{font-size: 14px;line-height: 18px;}
			}
		}
	}
	.box-sorting{margin-bottom: 30px;}
	.box-form{
		&.form-contact{
			h2{font-size: 23px;line-height: 30px;}
		}
	}
	/*.box-milestone{
		.ls-milestone{
			h3{font-size: 28px;}
			.in-milestone{padding: 0 30px 0 0;}
			&:nth-child(even){
				.in-milestone{padding: 0 0 0 30px;}
			}
			&.slick-current,&.active{
				h3{font-size: 33px;}
			}
		}
	}*/
	.box-milestone{
		margin: 40px 0 20px;
		&:before{display:none;}
		.dots{display: none;}
		.ls-milestone{
			&:before{display:none;}
			.in-milestone{width: 100%;padding: 0;text-align: center;}

			&.slick-current,&.active{
				h3{font-size: 28px;}
			}

			&:nth-child(even){
				.in-milestone{padding: 0;text-align: center;}
			}
		}
		.slick-arrow{
			left: 0;margin-left: 0;
			&.slick-prev{@include transform(rotate(-90deg));top: 8px;}
			&.slick-next{@include transform(rotate(-90deg));bottom: auto;top: 8px;left: auto;right: 0;}
		}
	}
	.tx-Reliance{
		ol{
			li{
				font-size: 16px;line-height: 24px;padding-left: 25px;margin-bottom: 15px;
				&:before{font-size:16px;}
			}
		}
		ul{
			li{
				font-size: 16px;line-height: 24px;margin-bottom: 15px;padding-left: 25px;
				&:before{left:0;width: 8px;height: 8px;top: 10px;}
			}
		}
	}
	.link-download{width: 400px;}
	.wpcontent{
		.linered{margin-left: 0;}
	}
	.sosmed-inline{
		margin-bottom: 30px;
		a{
			&.icwp{background-size: auto 200%;height: 15px;width: 40px;}
		}
	}
	.quotes{
		padding: 0 50px;font-size: 23px;line-height: 31px;font-style: italic;
		&:before{top:0;left: 15px;background-size: 100%;width: 20px;height: 20px;}
	}
	.contenterror{
		.mid{width: 80%;}
	}
}